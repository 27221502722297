const mutations = {
    // 当前用户信息
    commonUserInfo(state, params) {
        state.commonUserInfo = params;
    },
    // 当前用户信息
    commonUserInfoTree(state, params) {
        state.commonUserInfoTree = params;
    },
    // 将当前top导航选中tab
    currentTopTab(state, params) {
        state.currentTopTab = params;
    },
    // 进度
    percentage(state, params) {
        state.percentage = params;
    },
    // 显示进度
    showPercentage(state, params) {
        state.showPercentage = params;
    },
    // 下载的密码-增加
    addDownLoadKeyList(state, params) {
        state.downLoadKeyList.push(params);
    },
    // 下载的密码-删除
    deleteDownLoadKeyList(state, params) {
        state.downLoadKeyList = state.downLoadKeyList.filter(item => item !== params);
    },
    // 订单id
    rechargeOrderId(state, params) {
        state.rechargeOrderId = params;
    },
    // 占桩id
    accountPileFeeOrderId(state, params) {
        state.accountPileFeeOrderId = params;
    },
    // 当前系统类型： 二轮、四轮
    bizType(state, params) {
        state.bizType = params;
    },
    bizTypes(state, params) {
        localStorage.setItem('biz_types', JSON.stringify(params))
        state.bizTypes = params;
    },
    rechargeOrder(state, params) {
        state.rechargeOrder = params;
    },
    rechargeOrder2(state, params) {
        state.rechargeOrder2 = params;
    },
    bankStatementOrder(state, params) {
        state.bankStatementOrder = params;
    },
};
export default mutations;
