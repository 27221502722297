let state = {
    // 当前用户信息
    commonUserInfo:{

    },
    // 权限树
    commonUserInfoTree:[

    ],
    // 将当前top导航选中tab
    currentTopTab: null,
    // 进度
    percentage: 0,
    // 显示进度组件
    showPercentage: false,
    // 下载的密码
    downLoadKeyList: [],
    // 订单id
    rechargeOrderId: '',
    // 占桩id
    accountPileFeeOrderId: '',
    // 当前系统类型： 二轮1、四轮0
    bizType: 0,
    // 拥有的权限
    bizTypes: JSON.parse(localStorage.getItem('biz_types')) || [],
    // 充电订单页面form记忆
    rechargeOrder:{},
    // 充电订单页面form记忆-两轮
    rechargeOrder2:{},
    // 资金流水页面form记忆
    bankStatementOrder: {}
};
export default state;
